import Role from './common/data/role';

const pages = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/',
		icon: 'Dashboard',
		showInMenu: [Role.None, Role.Mini, Role.Basic, Role.Advanced, Role.Premium],
	},
	profile: {
		id: 'profile',
		text: 'Profile',
		path: '/profile',
		icon: 'Person',
		showInMenu: [],
	},
	login: {
		id: 'login',
		text: 'Login',
		path: '/auth/login',
		icon: 'Login',
		showInMenu: [],
	},
	signUp: {
		id: 'signUp',
		text: 'Sign Up',
		path: '/auth/sign-up',
		icon: 'PersonAdd',
		showInMenu: [],
	},
	resetPassword: {
		id: 'resetPassword',
		text: 'Reset Password',
		path: '/auth/reset-password',
		icon: 'QuestionMark',
		showInMenu: [],
	},
	resetPasswordConfirm: {
		id: 'resetPasswordConfirm',
		text: 'Reset Password Confirmation',
		path: '/auth/reset-password-confirm',
		icon: 'QuestionMark',
		showInMenu: [],
	},
	confirmEmail: {
		id: 'confirmEmail',
		text: 'Confirm Email',
		path: '/auth/confirm-email',
		icon: 'QuestionMark',
		showInMenu: [],
	},
	changeEmailConfirm: {
		id: 'changeEmailConfirm',
		text: 'Confirm Email Change',
		path: '/profile/change-email-confirm',
		icon: 'QuestionMark',
		showInMenu: [],
	},
	startWOWFree: {
		id: 'startWOWFree',
		text: 'Start WOW (Free)',
		path: '/start-wow-free',
		icon: 'CircleSquare',
		showInMenu: [Role.None],
	},
	// FIXME ausgebaut für Liquidierung
	// checkout: {
	// 	id: 'checkout',
	// 	text: 'Checkout',
	// 	path: '/checkout',
	// 	icon: 'Local Offer',
	// 	showInMenu: [Role.None],
	// },
	startWOW: {
		id: 'startWOW',
		text: 'Start WOW',
		path: '/start-wow',
		icon: 'CircleSquare',
		showInMenu: [Role.Mini, Role.Basic, Role.Advanced, Role.Premium],
	},
	flyWOW: {
		id: 'flyWOW',
		text: 'Fly WOW',
		path: '/fly-wow',
		icon: 'MoonStars',
		showInMenu: [Role.Basic, Role.Advanced, Role.Premium],
	},
	placeWOW: {
		id: 'placeWOW',
		text: 'Place WOW',
		path: '/place-wow',
		icon: 'Grid1X2',
		showInMenu: [Role.Advanced, Role.Premium],
	},
	viewWOW: {
		id: 'viewWOW',
		text: 'View WOW',
		path: '/view-wow',
		icon: 'Chair',
		showInMenu: [Role.Premium],
	},
	settings: {
		id: 'settings',
		text: 'WOW Settings',
		path: '/settings',
		icon: 'Settings',
		showInMenu: [Role.Advanced, Role.Premium],
	},
};

export default pages;
